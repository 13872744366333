import Modal from "react-awesome-modal";

import { firestore } from "../../firebase/firebase.utils";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useState } from "react";
import "./pay-list.style.scss";
import { Link } from "react-router-dom";
export const Card = ({ data: doc }) => {
  const DepartmentsRef = firestore.collection("settings");
  const dquery = DepartmentsRef;
  const [settings] = useCollectionData(dquery, { idField: "id" });
  const [visible, setVisibility] = useState(false);

  return (
    <div key={doc.id}>
      <Modal
        visible={visible}
        effect="fadeInUp"
        onClickAway={() => setVisibility(!visible)}
      >
        <div className="modal">
          <h1>-{doc.projectID}-</h1>
          <h3>Pick a department...</h3>
          <div>
            {settings &&
              settings.map((dept) =>
                dept.departments.map((dept, index) => (
                  <span
                    className=""
                    key={index}
                    onClick={() => {
                      var cityRef = firestore
                        .collection("Projects")
                        .doc(doc.id);
                      setVisibility(!visible);
                      return cityRef.update({
                        stage: dept,
                        status: "assigned",
                      });
                    }}
                  >
                    {dept}
                  </span>
                ))
              )}
          </div>
        </div>
      </Modal>

      <div
        className={`${doc.quoteStatus} card ${
          doc.handler ? "assigned" : "pending"
        }`}
        key={doc.id}
      >
        <div className="front" data-handler={doc.handler || "youPoi"}>
          <div className="head" key={doc.id} tabIndex="1">
            {doc.payments && (
              <div
                className="float"
                onClick={() => !doc.handler && setVisibility(!visible)}
              >
                {doc.status === "assigned" ? (
                  <i class="bx bx-loader float"></i>
                ) : (
                  <i class="bx bx-time-five float"></i>
                )}
                {doc.status === "assigned" && doc.handler ? (
                  <i class="bx bx-male float left"></i>
                ) : (
                  ""
                )}
              </div>
            )}

            <h1>-{doc.projectID}-</h1>
          </div>
          <h3 style={{ textAlign: "center", fontSize: "1.5rem" }}>
            {doc.companyName}
          </h3>
          {doc.payments ? (
            <div className="i payStatus">
              {!doc.outstanding ? "Fully Paid" : "paid half"}
            </div>
          ) : (
            <div className="i payStatus">No payment</div>
          )}

          <div className="stage">{doc.stage || "Marketing"}</div>

          <div
            className="progress"
            style={{ width: doc.progress <= 100 && doc.progress + "%" }}
          ></div>
        </div>
        <div className="back">
          <h3 style={{ textAlign: "center" }}>{doc.companyName}</h3>
          <Link to="/">
            <h2>
              <i className="bx bx-box"></i>
              {doc.QID || <span>no quote</span>}
            </h2>
          </Link>
          <Link
            to={{
              state: { ...doc, Type: "cash" },
              pathname: "/payments",
            }}
          >
            <h2>
              <i className="bx bx-credit-card-front type"></i>
              {doc?.payments ? (
                doc.payments[doc.payments.length - 1]
              ) : (
                <span>no payment</span>
              )}
            </h2>
          </Link>
          <Link to="/">
            <h2>
              <i className="bx bx-note"></i>
              {doc.invoiceID || <span>no invoice</span>}
            </h2>
          </Link>
          <button className={doc.handler && "handler"}>
            {/* {doc.handler || 'Assign'} */}
            {/* {doc.status === 'pending' && 'please assign'} */}
            {doc.status === "assigned" && doc.handler
              ? doc.handler
              : "please assign"}
          </button>
        </div>
      </div>
    </div>
  );
};
