import "./FormInput.style.css";

export const FormInput = ({
  FA_icon,
  handleChange,
  placeHolder,
  ...otherProps
}) => (
  <div className="input-field">
    <i className={FA_icon}></i>
    <input
      onChange={handleChange}
      placeholder={placeHolder ? placeHolder : null}
      {...otherProps}
    />
  </div>
);
