import { BodyComponent } from "../../components/layouts/body-component";
import "./pay-list.style.scss";
import { Link } from "react-router-dom";
import { firestore } from "../../firebase/firebase.utils";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useState, useEffect } from "react";
import { useStore } from "./../../zustand/store";
import { Card } from "./card.component";

const PayList = (params) => {
  const { currentUser } = useStore((state) => state);
  const ProjectRef = firestore.collection("projects");
  let query = ProjectRef.orderBy("createdAt");
  // if (currentUser.department !== 'marketing') {
  //   query = ProjectRef.where('stage', '==', currentUser.department)
  // }
  const [project] = useCollectionData(query, { idField: "id" });
  const DepartmentsRef = firestore.collection("settings");
  const dquery = DepartmentsRef;
  const [settings] = useCollectionData(dquery, { idField: "id" });
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  useEffect(() => {
    const results =
      project &&
      project.filter(
        (quote) =>
          quote?.companyName
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          quote?.Company?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          quote?.LastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          quote?.quoteStatus
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          quote?.payStatus?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          quote?.projectID?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          quote?.stage?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    setSearchResults(results);
    // console.log(q);
  }, [searchTerm, project]);
  return (
    <BodyComponent className="PayList">
      <header>
        <h1 className="title">Projects</h1>
        <Link
          to={{
            message: "pick a client before making a quote",
            // pathname: _client ? '/quote' : '/clients',
            pathname: "/invoice-list",
          }}
          className="button"
        >
          view Invoices
        </Link>
      </header>
      <section>
        <div className="i">
          <div>
            <i class="bx bx-loader type"></i>
            Assigned
          </div>
          <div>
            <i className="bx bx-time-five type"></i>
            Pending
          </div>
          <div>
            <i className="bx bxs-meteor type"></i>
            Canceled
          </div>
          <div>
            <i className="bx bxs-inbox type"></i>
            Done
          </div>
        </div>
        <input
          type="text"
          placeholder="Search..."
          onInput={(e) => setSearchTerm(e.target.value)}
        />
      </section>
      <main>
        {project &&
          searchResults?.map((doc) => <Card data={doc} key={doc.id} />)}
      </main>
    </BodyComponent>
  );
};
export default PayList;
