import { toast } from "react-toastify";
const tableZ = () => {};
export const getPrice = (e, products) => {
  const tr = e.target.parentElement.parentElement;
  const price = tr.querySelector(".price");
  const uid = document.querySelector(".uid").innerText;
  const quality = tr.querySelector(".Quantity");
  // console.log("products", products);
  const product =
    products &&
    products.filter((p) =>
      p?.item?.toLowerCase().includes(e.target.value.toLowerCase())
    );
  if (product[0]?.price == 0) {
    let pin = prompt("Please pin to overwrite price...");
    if (pin === uid) {
      if (quality?.value === "") quality.value = 1;

      price.classList.add("undefined");
      price.placeholder = "?";
      price.disabled = false;
      price.addEventListener("click", function () {
        price.classList.add("auth");
      });
    } else {
      toast("you cant issue a quote for this product...");
    }
  } else {
    if (quality?.value === "") quality.value = 1;
    price.value = product[0]?.price;
  }
};
export const addNewRow = (dataList, products, quote) => {
  const row = document.createElement("tr");
  row.className = "single-row";
  row.innerHTML = `<td data-label='Product name' class="product-name">
  <input 
    type="text" 
    placeholder="Product name" 
    class="product left" 
    value='${quote?.product || ""}'
    id="product" 
    list='pr'
    multiple
  />
  <datalist id='pr'>
  <option value="Hello" className='op'>Hello</option></datalist>
  </td>
  <td data-label='Quantity'>
  <input type="number" placeholder="0" name="Quantity" class="Quantity" id="Quantity" 
  value='${quote?.quantity || ""}'/>
  </td>
  <td data-label='Price'>

  <input type="number" placeholder="0" name="price" class="price" id="price" 
  value='${quote?.price || ""}' disabled/>
  </td>
  <td data-label='Amount'>
  <input type="number" placeholder="0" name="amount" class="amount" id="amount"  disabled 
  value='${quote?.amount || ""}'
  />
  </td><td><i class='bx bxs-trash-alt delete'></i>
  </td>`;
  var option = "";
  products?.map(
    (doc) =>
      (option += ` <option
      key='${doc.id}'
      value='${doc.item}'
      className='op'
    >
     ${doc.description} ~ / R ${doc.price} / ${doc.subscription}
    </option>`)
  );
  row
    .querySelector(".product")
    .addEventListener("input", (e) => getPrice(e, products));
  row.querySelector("#pr").innerHTML = option;
  document
    ?.getElementById("table-body")
    ?.insertBefore(row, document.getElementById("table-body").lastElementChild);
};
//Get the overall sum/Total
export const formatter = new Intl.NumberFormat("en-ZA", {
  style: "currency",
  currency: "ZAR",
  minimumFractionDigits: 2,
});
export const overallSum = () => {
  var arr = document?.getElementsByName("amount");
  var total = 0;
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].value) {
      total += +arr[i].value;
    }
    // total = formatter.format(total);
    document.getElementById("total").value = total;
    document.querySelector(".total").value = total;
  }
};
//GET INPUTS, MULTIPLY AND GET THE ITEM PRICE

export const getInput = () => {
  var rows = document?.querySelectorAll("tr.single-row");
  rows.forEach((currentRow) => {
    var inputs = document?.querySelectorAll("input");
    inputs.forEach((currIn) => {
      currIn.classList.remove("undone");
    });
    var product = currentRow.querySelector("#product")?.value;
    var Quantity = currentRow.querySelector("#Quantity")?.value;
    var price = currentRow.querySelector("#price")?.value;
    // data.push(currentRow);
    const amount = Quantity * price;
    currentRow.querySelector("#amount").value = amount;
    overallSum();
    var input = product?.toLowerCase();
    let x = currentRow.querySelectorAll(".animals");
    for (let i = 0; i < x.length; i++) {
      x[i].addEventListener("click", (e) => {
        x[i].parentElement.parentElement.firstElementChild.value =
          e.target.innerText;
        // getInput();
        x[i].parentElement.style.display = "none";
        // console.log(product);
      });
      if (!x[i].innerHTML.toLowerCase().includes(input)) {
        x[i].style.display = "none";
      } else if (x[i].innerHTML.toLowerCase().includes(input)) {
        x[i].style.display = "list-item";
        if (input === "") {
          x[i].style.display = "none";
        }
      }
    }
  });
  // return data;
};
export const handleTable = (e) => {
  let el = e.target;
  if (e.target.classList.contains("delete")) {
    delRow(el);
    overallSum();
  }
};
export const handleTotal = (e) => {
  getInput();
};
//Target row and remove from DOM;
export const delRow = (el) => {
  el.parentNode.parentNode.parentNode.removeChild(el.parentNode.parentNode);
};
/* can be used to filter Objects */
export const filter = (obj, predicate) => {
  Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => (res[key] = obj[key])(res), {});
};

export default tableZ;
