import "./profile.style.scss";
import { auth, firestore } from "../../firebase/firebase.utils";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
export const Profile = () => {
  const [user] = useAuthState(auth);
  const [value] = useDocument(firestore.doc(`users/${user.uid}`), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  console.log(value.data().email);
  return (
    <div className="profile">
      <h1>Profile</h1>
      <main>
        <div className="g">
          <div className="i">
            <div className="label">Email</div>
            <input type="text" value={value.data().email} />
          </div>
          <div className="i">
            <div className="label">display name</div>
            <input type="text" value={value.data().displayName} />
          </div>
          <div className="i">
            <div className="label">Change password...</div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default Profile;
