import './staff.style.scss';
import { useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import {
  auth,
  authTwo,
  createDoc,
  firestore,
} from './../../firebase/firebase.utils';
// import CurrencyInput from 'react-currency-input-field';
// import DatePicker from 'react-datepicker';
// import TextareaAutosize from 'react-textarea-autosize';
import Select from 'react-select';
// import { useState } from 'react';
import Modal from 'react-awesome-modal';
import { useAuthState } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';

// import Modal from 'react-awesome-modal';
const Staff = () => {
  const [user] = useAuthState(auth);
  const [visible, setVisibility] = useState(false);
  const [currentStaff, setCurrentStaff] = useState(false);
  const [Department, setDepartment] = useState(false);
  const [value, loading, error] = useCollection(firestore.collection('users'), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const methods = [
    { label: 'branding', value: 'branding' },
    { label: 'media', value: 'media' },
    { label: 'design', value: 'money' },
  ];
  const [staff_visible, setSVisibility] = useState(false);
  const handleReg = (params) => {
    authTwo
      .createUserWithEmailAndPassword('sifisovks@gmail.com', 'serame')
      .then((userCredential) => {
        userCredential.user.sendEmailVerification();
        const email = userCredential.user.email;
        var displayName = email.substring(0, email.lastIndexOf('@'));
        createDoc(
          'users',
          {
            uniqueField: 'email',
            uniqueValue: email,
          },
          {
            displayName,
            email,
            Department,
          }
        );
        alert('Email sent');
      });
    authTwo.signOut().catch(alert);
  };
  return (
    <div className='staff'>
      <h1 className='title'>
        Staff
        <Modal
          visible={visible}
          effect='fadeInUp'
          onClickAway={() => setVisibility(false)}
        >
          <div className='modal'>
            <h3>Sent Invitation</h3>
            <input type='email' />
            <Select
              className='select'
              placeholder='Select Department'
              value={methods.find((obj) => obj.value === Department)} // set selected value
              options={methods} // set list of the methods
              onChange={(e) => {
                setDepartment(e.value);
              }} // assign onChange function
            />
            <button onClick={() => handleReg()}>sent invite</button>
          </div>
        </Modal>
      </h1>

      <div className='member current' key={user.id}>
        <div className='info'>
          <span className={`department unknown`}>Unknown</span>
          <span>
            <i className='bx bx-user'></i>
          </span>
          <div className='data'>
            <h3>{user.displayName}</h3>
            <small>{user.email}</small>
          </div>
        </div>
        <div className='edit-card'>
          <div className='form'>the form will go here..</div>
        </div>
      </div>
      <div className='all-staff'>
        <div className='info member add' onClick={() => setVisibility(true)}>
          <span>
            <i className='bx bx-plus'></i>
          </span>
          <h2>add staff member</h2>
        </div>

        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <span>Staff members: Loading...</span>}
        {value?.docs.map((doc) => (
          <>
            <div
              className='member'
              key={doc.id}
              onClick={() => setSVisibility(true)}
              onMouseEnter={() => setCurrentStaff(doc.data())}
            >
              <div className='info'>
                <span
                  className={`department ${doc.data().department
                      ? doc.data().department.toLowerCase()
                      : 'unknown'
                    }`}
                >
                  {doc.data().department
                    ? doc.data().department.toUpperCase()
                    : 'Unknown'}
                </span>
                <span>
                  <i className='bx bx-user'></i>
                </span>
                <div className='data'>
                  <h3>
                    {doc.data().FirstName
                      ? doc.data().FirstName + ' ' + doc.data().LastName
                      : doc.data().displayName}{' '}
                  </h3>
                  <small>{doc.data().email}</small>
                </div>
                {/*    <div className=''>
              <h3>Skill</h3>
              <small className='departments'>
              <div className='div design'>Lorem, ipsum dolor </div>
              <div className='div auto_body'> adipisicing</div>
              <div className='div marketing'>adipisicing</div>
              <div className='div hr'>animi, placeat, </div>
              </small>
            </div> */}
              </div>
            </div>
          </>
        ))}
      </div>
      <Modal
        visible={staff_visible}
        effect='fadeInUp'
        onClickAway={() => setSVisibility(false)}
      >
        <div className='staffModal'>
          <i
            className='bx bx-trash'
            onDoubleClick={() => toast('You double clicked son...')}
          ></i>
          <div className='i'>
            <i className='bx bxs-user-badge'></i>
            <input
              type='text'
              value={
                currentStaff.FirstName
                  ? currentStaff.FirstName + ' ' + currentStaff.LastName
                  : currentStaff.displayName ?? 'unknown'
              }
            />
          </div>
          <div className='i'>
            <i className='bx bxs-navigation'></i>
            <input type='text' value={currentStaff.email} />
          </div>
          <div className='i'>
            <i className='bx bx-clipboard'></i>
            <input
              type='text'
              value={
                currentStaff.department + ' department' ?? 'unknown department'
              }
            />
          </div>

          <button className='btn-update '>Update</button>
        </div>
      </Modal>
    </div>
  );
};

export default Staff;
