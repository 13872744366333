import "./quote-list.style.scss";

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { BodyComponent } from "./../../components/layouts/body-component";
import Modal from "react-awesome-modal";
import { firestore } from "../../firebase/firebase.utils";
import { useCollection } from "react-firebase-hooks/firestore";
import { useStore } from "../../zustand/store";

const QuoteList = () => {
  const [value, loading, error] = useCollection(
    firestore.collection("Projects"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const { _client, allClients, setCurrentClient } = useStore((state) => state);
  // const { today } = useStore((state) => state);
  const [visible, setVisibility] = useState(false);
  const [p_visible, setPVisibility] = useState(false);
  const [c_visible, setCVisibility] = useState(false);
  const [Quarter, setQuarter] = useState(false);
  const [QuoteList, setQuoteList] = useState({ List: [], qID: "" });
  const quuoo = (params) => {
    setVisibility(true);
    setQuarter(params);
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  useEffect(() => {
    const q = value?.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    const results = q?.filter(
      (quote) =>
        quote?.companyName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        quote?.quoteStatus?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        quote?.quoteID?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        quote?.Company?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        quote?.LastName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
    // console.log(q);
  }, [searchTerm, value]);
  // const { user } = auth;
  // console.log(user);
  // user?.updateProfile({
  //   emailVerified: true,
  // });
  // console.log(user);
  return (
    <BodyComponent className="quoteLis">
      <main>
        <Modal
          visible={c_visible}
          effect="fadeInDown"
          onClickAway={() => setCVisibility(false)}
        >
          <div className="pickClientModal">
            <header>
              <p>in order to continue</p>
              <h1>Pick a client</h1>
            </header>
            <div className="items">
              {allClients.map((doc, index) => (
                <Link
                  onClick={() => setCurrentClient(doc)}
                  to="/quote"
                  className="item"
                  key={index}
                >
                  <i
                    class={`bx  ${
                      doc.Type === "private"
                        ? "bxs-building-house"
                        : doc.Type === "public"
                        ? "bx-buildings"
                        : "bx-user"
                    }`}
                  ></i>
                  <div className="">
                    <div className="">
                      {doc.FirstName
                        ? doc?.FirstName + " " + doc?.LastName
                        : doc.Company}
                    </div>
                    <small>{doc.Email}</small>
                  </div>
                </Link>
              ))}
            </div>
            <footer>
              <span>
                <i className="bx bx-user"></i>
                walk-in
              </span>
              <span>
                <i className="bx bx-buildings"></i>
                public
              </span>
              <span>
                <i className="bx bxs-building-house"></i>
                private
              </span>
            </footer>
          </div>
        </Modal>
        <header>
          <h1 className="title">Quotes</h1>
          <button
            to={{
              message: "pick a client before making a quote",
              pathname: _client ? "/quote" : "/clients",
            }}
            onClick={() => setCVisibility(!false)}
            className="button"
          >
            Make a quote
          </button>
        </header>
        <section>
          <div className="_cards">
            <div className="pending card">
              <div className="tab ">
                <h1>Pending</h1>
                <i className="bx bxs-hourglass"></i>
              </div>
            </div>
            <div className="approved card">
              <div className="tab">
                <h1>Approved</h1>
                <i className="bx bxs-calendar-check"></i>
              </div>
            </div>
            <div className="overdue card">
              <div className="tab">
                <h1>Overdue</h1>
                <i className="bx bx-task-x"></i>
              </div>
            </div>
          </div>
          <div className="search">
            <input
              type="text"
              placeholder="Search..."
              onInput={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {error && <strong>Error: {JSON.stringify(error)}</strong>}
          {loading && <span>Collection: Loading...</span>}
          {value && (
            <div className="cards">
              {searchResults?.map((doc) => {
                const Quote = doc;
                // const { status } = Quote;
                return (
                  <div key={Quote.id}>
                    <Modal
                      visible={p_visible}
                      effect="fadeInUp"
                      onClickAway={() => setPVisibility(false)}
                    >
                      <div className="quoteModal">
                        <p>{QuoteList.Name}</p>
                        <h1>Products</h1>

                        {QuoteList.List.map((q, index) => (
                          <div className="item" key={index}>
                            <span className="name">{q.product}</span>
                            <span className="quantity">x {q.quantity}</span>
                          </div>
                        ))}
                        <footer>
                          <h2>{QuoteList.qID}</h2>
                          <i className="bx bx-box"></i>
                        </footer>
                      </div>
                    </Modal>
                    <div className={`${Quote.quoteStatus} card`} key={Quote.id}>
                      <div className="status" tabIndex="1">
                        <h1>
                          {doc.quoteStatus}
                          <i className="bx bxs-chevron-right"></i>
                        </h1>
                      </div>
                      <div className="by">
                        <h3>{Quote.companyName}</h3>
                        {/* <span>{Quote.Quote.length} items</span> */}
                        {/* <Link to='/Payment'> */}
                        {Quote.quoteStatus === "pending" && (
                          <>
                            <button
                              onClick={() => setPVisibility(true)}
                              onMouseEnter={() =>
                                setQuoteList({
                                  Name: Quote.companyName,
                                  List: Quote.Quote,
                                  qID: Quote.quoteID,
                                })
                              }
                            >
                              View Items
                            </button>
                            <button onClick={() => quuoo(Quote)}>
                              Make Payment
                            </button>
                          </>
                        )}
                        {Quote.quoteStatus === "overdue" && (
                          <>
                            <p>you can't continue with this quote...</p>
                            <button
                              onClick={() => setPVisibility(true)}
                              onMouseEnter={() =>
                                setQuoteList({
                                  List: Quote.Quote,
                                  qID: Quote.quoteID,
                                })
                              }
                            >
                              View Items
                            </button>
                          </>
                        )}
                        {Quote.quoteStatus === "approved" && (
                          <>
                            {Quote.outstanding !== 0 && (
                              <>
                                <button>pay again</button>
                              </>
                            )}
                            {Quote.outstanding === 0 && (
                              <>
                                <p> completed payment</p>
                              </>
                            )}
                            {/* <button onClick={() => setPVisibility(true)} onMouseEnter={() =>
                              setQuoteList({
                                List: Quote.Quote,
                                qID: Quote.quoteID,
                              })
                            }>View Invoice</button> */}
                          </>
                        )}
                        {/* </Link> */}
                      </div>
                      <div className={`tab ${doc.quoteStatus}`}>
                        <h1>{Quote.quoteID}</h1>

                        <div className="companyType">
                          <i
                            className={`bx ${
                              Quote.companyType === "private"
                                ? "bxs-building-house"
                                : Quote.companyType === "public"
                                ? "bx-buildings"
                                : "bx-user"
                            }`}
                          ></i>
                        </div>

                        {/* <i className='bx bx-trash'></i> */}
                        {/* <Link
                        to={{ state: { ...Quote }, pathname: '/payments' }}
                        onClick={() => setVisibility(false)}
                        className={`button ${doc.status}`}
                      >
                        Make Payment
                      </Link> */}
                      </div>
                    </div>
                    <Modal
                      visible={visible}
                      effect="fadeInUp"
                      onClickAway={() => setVisibility(false)}
                    >
                      <div className="modal">
                        <h2>{Quarter.quoteID}</h2>
                        <div className="center">
                          {/* <Link
                            to={{
                              state: { ...Quarter, Type: 'eft' },
                              pathname: '/payments',
                            }}
                            className='span'
                          >
                            <i className='bx bx-wallet-alt'></i>
                            <h4>EFT</h4>
                          </Link>
                          <Link
                            to={{
                              state: { ...Quarter, Type: 'credit' },
                              pathname: '/payments',
                            }}
                            className='span'
                          >
                            <i className='bx bx-credit-card-front'></i>
                            <h4>Credit</h4>
                          </Link> */}
                          <Link
                            to={{
                              state: { ...Quarter, Type: "cash" },
                              pathname: "/payments",
                            }}
                            className="span"
                          >
                            <i className="bx bx-wallet"></i>
                            <h4>Cash</h4>
                          </Link>
                        </div>
                      </div>
                    </Modal>
                  </div>
                );
              })}
            </div>
          )}
          {/*     <div className='cards'>
            <div className='pending card'>
              <div className='status'>
                <h1>
                  Pending<i className='bx bxs-chevron-right' tabIndex='0'></i>
                </h1>
              </div>
              <div className='by'>
                <h3>Serame group</h3>5 items
                <button> Make Payment</button>
              </div>
              <div className='tab pending'>
                <h1>QUO-35467</h1>
                <i className='bx bx-trash'></i>
                <Link to='/' className='button pending'>
                  Make Payment
                </Link>
              </div>
            </div>
            <div className='approved card'>
              <div className='status'>
                <h1>
                  Approved<i className='bx bxs-chevron-right' tabIndex='0'></i>
                </h1>
              </div>
              <div className='by'></div>
              <div className='tab approved'>
                <h1>QUO-35467</h1>
                <Link to='/' className='button approved'>
                  <span className='a'>
                    <i className='bx bx-paperclip'></i>
                  </span>
                  <span className='b'>View Payment</span>
                </Link>
              </div>
            </div>
            <div className='overdue card'>
              <div className='status'>
                <h1>
                  Overdue
                  <i className='bx bxs-chevron-right' tabIndex='0'></i>
                </h1>
              </div>
              <div className='by'></div>
              <div className='tab overdue'>
                <h1>QUO-35467</h1>
                <Link to='/' className='button overdue'>
                  Make New
                </Link>
              </div>
            </div>
          </div>
         */}
        </section>
      </main>
    </BodyComponent>
  );
};
export default QuoteList;
