import "./user-list.style.scss";
import { useEffect, useState } from "react";
import { BodyComponent } from "./../../components/layouts/body-component";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, firestore } from "../../firebase/firebase.utils";
import Select from "react-select";

const options = [
  { value: "marketing", label: "Marketing" },
  { value: "design", label: "Design" },
  { value: "print", label: "Print" },
];
export const UserList = () => {
  const [user] = useAuthState(auth);
  const UsersRef = firestore.collection("users");
  const query = UsersRef.where("email", "<", user?.email).orderBy("email");
  const [users] = useCollectionData(query, { idField: "id" });
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };
  // const people = [
  //   { name: 'Siri', last: 'Alexa' },
  //   { name: 'Google', last: 'Facebook' },
  // ];
  useEffect(() => {
    // console.log(location);
    const results =
      users &&
      users?.filter(
        (person) =>
          person.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          person.department?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          person.displayName?.toLowerCase().includes(searchTerm.toLowerCase())
        // || person.active?.toLowerCase().includes(
        //   searchTerm.toLowerCase()
        // )
      );
    setSearchResults(results);
  }, [searchTerm, users]);
  return (
    <BodyComponent className="UserList">
      <div>
        <h1 className="title">Staff</h1>
        <h4>Assign departments and access...</h4>
      </div>

      <header>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleChange}
        />
        <div className="">
          <span>
            {" "}
            <i className="bx bx-face"></i> active{" "}
          </span>
          <span>
            <i className="bx bxs-face-mask"></i> not active
          </span>
        </div>
      </header>
      <ul className="">
        {users &&
          searchResults?.map((user) => (
            <li
              key={user.id}
              className={`list-item  ${user.active === true ? "green" : "red"}`}
            >
              <div className="info">
                <span>{user.displayName}</span>
                <span>{user.email}</span>
              </div>
              <Select
                className="select"
                options={options}
                defaultValue={
                  user.department && {
                    label: user.department,
                    value: user.department,
                  }
                }
                placeholder="Department..."
                onChange={(newValue, actionMeta) => {
                  console.log(newValue.value);
                  var cityRef = firestore.collection("users").doc(user.id);

                  // Set the 'capital' field of the city
                  return cityRef.update({ department: newValue.value });
                  // console.log(`action: ${actionMeta.action}`);
                }}
                key={user.id}
              />
              <div
                className="status"
                onClick={async () => {
                  var cityRef = firestore.collection("users").doc(user.id);

                  // Set the 'capital' field of the city
                  return cityRef.update({ active: !user.active });
                }}
              >
                {user.active ? (
                  <i className="bx bx-face"></i>
                ) : (
                  <i className="bx bxs-face-mask"></i>
                )}
              </div>
              <i className="bx bxs-trash"></i>
            </li>
          ))}
      </ul>
    </BodyComponent>
  );
};
export default UserList;
