import "./clients.style.scss";

import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";

import Modal from "react-awesome-modal";
// import { setAllClients } from '../../redux/all-client/all-clients.actions';
// import { setCurrentClient } from './../../redux/client/client.actions';
import { useStore } from "../../zustand/store";

const AllClients = () => {
  const {
    allClients,
    setCurrentClient,
    // setCurrentUser,
    // setAllClients,
    // setUsers,
    // fetch_client,
    // set_redux,
    // clearClient,
    // setProjects,
  } = useStore((state) => state);
  const [visible, setVisibility] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const results =
      allClients &&
      allClients.filter(
        (person) =>
          person.FirstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          person.LastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          person.Type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          person.Contact.MobileNumber?.toLowerCase().includes(
            searchTerm.toLowerCase()
          ) ||
          person.Contact.PhoneNumber?.toLowerCase().includes(
            searchTerm.toLowerCase()
          ) ||
          person.Email?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    setSearchResults(results);
  }, [searchTerm, allClients]);

  return (
    <div className="clients-list">
      <div className="search">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleChange}
        />
        <Modal
          visible={visible}
          effect="fadeInUp"
          onClickAway={() => setVisibility(!visible)}
        >
          <div className="modal">
            {/* <i className='bx bx-x' onClick={() => setVisibility(false)}></i> */}
            <p onClick={() => setVisibility(!visible)}>Type of client...</p>
            <div className="type">
              <NavLink
                to={{
                  pathname: "/add-client",
                  state: { title: "walk-in" },
                }}
              >
                <i class="bx bx-user"></i>
                <p>Walk In</p>
              </NavLink>
              <NavLink
                to={{
                  pathname: "/add-client",
                  state: { title: "private" },
                }}
              >
                <i class="bx bxs-building-house"></i>
                <p>Public</p>
              </NavLink>
              <NavLink
                to={{
                  pathname: "/add-client",
                  state: { title: "public" },
                }}
              >
                <i class="bx bx-buildings"></i>
                <p>Private</p>
              </NavLink>
            </div>
          </div>
        </Modal>
      </div>
      <div className="pick-client">
        <div
          to="add-client"
          className="client"
          onClick={() => setVisibility(true)}
        >
          <h2 className="">Add Client</h2>
          <i class="fas fa-plus"></i>
        </div>

        {searchResults?.map((doc) => (
          <div key={doc.id}>
            <Link onClick={() => setCurrentClient(doc)} to="/">
              <div className="client">
                <div className="options">
                  <i class="bx bx-file"></i>
                </div>
                <i
                  class={`bx  ${
                    doc.Type === "private"
                      ? "bxs-building-house"
                      : doc.Type === "public"
                      ? "bx-buildings"
                      : "bx-user"
                  }`}
                ></i>
                <div className="data">
                  {doc.FirstName
                    ? doc?.FirstName + " " + doc?.LastName
                    : doc.Company}
                  <small>{doc.Email}</small>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
// const mapStateToProps = ({ allClients }) => ({
//   allClients: allClients.allClients,
// });

// const mapDispatchToProps = (dispatch) => ({
//   setAllClients: (allClients) => dispatch(setAllClients(allClients)),
//   setCurrentClient: (client) => dispatch(setCurrentClient(client)),
// });
export default AllClients;
// export default connect(mapStateToProps, mapDispatchToProps)(AllClients);
