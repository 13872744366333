import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { setCurrentClient } from "./../redux/client/client.actions";
import { useDispatch } from "react-redux";
var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
var yyyy = today.getFullYear();
var h = today.getHours();
var m = today.getMinutes();

// today = mm + '/' + dd + '/' + yyyy;
today = yyyy + "-" + mm + "-" + dd;
today = dd + "-" + mm + "-" + yyyy;

export const useStore = create(
  persist(
    devtools((set) => ({
      today,
      setToday: (date) => {
        set(() => null);
      },
      /* Quote */
      quote: [],
      invoice: [],
      payment: [],
      Quotes: [],
      users: [],
      currentUser: "",
      currentClient: [],
      Projects: [],
      allClients: "",
      IID: "",
      Products: "",
      setIID: (users) => set((state) => ({ IID: users })),
      setUsers: (users) => set((state) => ({ users: users })),
      setProjects: (project) => set((state) => ({ Projects: project })),
      setProducts: (products) => set((state) => ({ Products: products })),
      setCurrentUser: (user) => set((state) => ({ currentUser: user })),
      setCurrentClient: (client) =>
        set((state) => ({
          currentClient: client,
          CID: client.id,
          projectCode: `${dd}${m}${client.Projects.length}'${client.id.slice(
            0,
            2
          )}`,
        })),
      clearCurrentClient: (client) => set((state) => ({ currentClient: "" })),
      setAllClients: (clients) => set((state) => ({ allClients: clients })),
      setQuote: (quote) => set((state) => ({ quote: quote })),
      clearQuote: () => set({ quote: [] }),
      Data: [],
      setData: (data) =>
        set((state) => {
          const d = JSON.stringify(data);
          localStorage.removeItem("invoice" || "quote");
          if (data.Type === "Quote") {
            localStorage.setItem("quote", d);
          } else {
            localStorage.setItem("invoice", d);
          }
          return { Data: data };
        }),
      clearData: () => set({ Data: [] }),
      /* Quote */

      setInvoice: (quote) =>
        set((state) => ({ quote: [...state.Invoice, quote] })),
      clearInvoice: () => set(() => ({ invoice: [] })),
      _client: [],
      fetch_client: (client) =>
        set((state) => {
          // state.save_client(client);
          return {
            _client: JSON.parse(localStorage.getItem("client")) || null,
          };
        }),
      set_redux: (client) =>
        set((state) => {
          // state.save_client(client);
          const dispatch = useDispatch();
          dispatch(setCurrentClient(state._client));
          return {
            quoteCode: `QUO-${dd}${state._client.Quotes.length + 1 + mm}`,
          };
        }),
      set_client: (client) => set(() => ({ _client: client })),
      set_w_client: (client) => set(() => ({ _client: client })),
      save_client: (client) =>
        set(() => {
          localStorage.removeItem("client");
          const c = JSON.stringify(client);
          localStorage.setItem("client", c);
          return {
            _client: client,
            // quoteCode: `QUO-${dd}${client.Quotes.length + 1 + h}`,
          };
        }),
      quoteCode: ``,
      setQuoteCode: (quoteTotal, initials) =>
        set((state) => ({
          quoteCode: `Q-${dd}${quoteTotal + m}${h}'${initials}`,
        })),
      setInvoiceCode: (initials) =>
        set((state) => ({
          invoiceCode: `I-${dd}${state.Projects?.length + m}${h}'${initials}`,
        })),
      setProjectCode: (initials) =>
        set((state) => ({
          projectCode: `${dd}${state.Projects?.length}'${initials}`,
        })),
      w_payCode: ``,
      setPayCode: (payTotal, initials) =>
        set((state) => ({
          payCode: `P-${dd}${payTotal + m}${h}'${initials}`,
        })),
      setQuotes: (Quotes) =>
        set((state) => ({ Quotes: { ...state.Quotes, ...Quotes } })),
      // clearQuoteCode: () => set({ quote: [] }),
      /* Invoice */
      clear_client: () =>
        set(() => {
          localStorage.removeItem("client");
          localStorage.removeItem("quote");
          localStorage.removeItem("invoice");
          return {
            _client: null,
            invoice: [],
            quote: [],
            order: [],
            Data: [],
          };
        }),
      /* Order */
      order: [],
      setOrder: (order) => set((state) => ({ order: [...state.order, order] })),
      clearOrder: () => set((state) => ({ order: [] })),
    }))
  )
);
