import { combineReducers } from 'redux';
import { allClientsReducer } from './all-client/all-clients.reducer';
import { clientReducer } from './client/client.reducer';
import { userReducer } from './user/user.reducer';

export default combineReducers({
  user: userReducer,
  allClients: allClientsReducer,
  client: clientReducer,
});
