import "./job-list.style.scss";

import { BodyComponent } from "./../../components/layouts/body-component";
import { firestore } from "../../firebase/firebase.utils";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useStore } from "../../zustand/store";
import { FFV } from "./../../firebase/firebase.utils";

export const JobList = () => {
  const { currentUser } = useStore((state) => state);
  const ProjectRef = firestore.collection("Projects");
  let query = ProjectRef.where("stage", "==", currentUser.department);
  // if (currentUser.department !== 'marketing') {
  //   query = ProjectRef.where('stage', '==', currentUser.department)
  // }
  const [projects] = useCollectionData(query, { idField: "id" });
  return (
    <BodyComponent className="JobList">
      <header>
        <h1>Jobs Lists</h1>
      </header>
      <input type="text" />
      <main>
        {projects > 0 ? (
          projects?.map((doc, index) => (
            <div className={`  ${doc.department || "Marketing"}`} key={index}>
              <div className="first">
                <div className="head">
                  <h2>{doc.projectID || "projectID"}</h2>
                  <span>{doc.department || "Marketing"}</span>
                </div>
                <div className="head">
                  <div className="text">Handler: </div>
                  <p>{doc.handler || "unpicked"}</p>
                </div>
              </div>
              <div className="middle">
                {doc.Quote.map(({ product }, index) => (
                  <p key={index}>{product}</p>
                ))}
              </div>
              <div className="last">
                {doc.handler ? (
                  <>
                    <button
                      className=""
                      onClick={() => {
                        var projectRef = firestore.doc(`Projects/${doc.id}`);
                        projectRef.update({
                          department: "",
                          handler: "",
                          stage: "",
                          status: "",
                        });
                        var userRef = firestore.doc(`users/${currentUser.id}`);
                        userRef.update({
                          workedOn: FFV.arrayUnion(doc.projectID),
                        });
                      }}
                    >
                      Done
                    </button>
                    <i
                      className="bx bxs-x-circle"
                      onClick={() => {
                        var projectRef = firestore.doc(`Projects/${doc.id}`);
                        projectRef.update({ handler: "" });
                        var userRef = firestore.doc(`users/${currentUser.id}`);
                        userRef.update({
                          workedOn: FFV.arrayUnion(doc.projectID),
                        });
                      }}
                    ></i>
                    {currentUser.displayName !== doc.handler && (
                      <button
                        className=""
                        onClick={() => {
                          var projectRef = firestore.doc(`Projects/${doc.id}`);
                          projectRef.update({
                            touchedBy: FFV.arrayUnion(currentUser.displayName),
                          });
                          var userRef = firestore.doc(
                            `users/${currentUser.id}`
                          );
                          userRef.update({
                            workingOn: FFV.arrayUnion(doc.projectId),
                          });
                        }}
                      >
                        co-accept
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    className=""
                    onClick={() => {
                      var projectRef = firestore.doc(`Projects/${doc.id}`);
                      return projectRef.update({
                        handler: currentUser.displayName,
                        stage: currentUser.department,
                        status: "assigned",
                        touchedBy: FFV.arrayUnion(currentUser.displayName),
                      });
                    }}
                  >
                    Accept
                  </button>
                )}
                {doc.note || <i class="bx bx-notification"></i>}
              </div>
            </div>
          ))
        ) : (
          <h1>No Jobs out </h1>
        )}
      </main>
    </BodyComponent>
  );
};
