// react
// import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
// firebase
import { auth } from "./firebase/firebase.utils";
// redux

import ClipLoader from "react-spinners/ClipLoader";
// pages
import SignIn from "./components/sign-in/SignIn.component";
import SignUp from "./components/sign-up/SignUp.component";
import ErrorPage from "./pages/error-page/error.components";
import { useAuthState } from "react-firebase-hooks/auth";
// import { setAllClients } from './redux/all-client/all-clients.actions';
// import ClientView from './views/client-view/client-view.component';
import "./App.scss";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useStore } from "./zustand/store";
import { createUserProfileDocument } from "./firebase/firebase.utils";

// import { InVOVO } from './views/invoice-view/invoice.component';
import { LoadApp } from "./views/loadApp/loadApp.component";
///
const App = () => {
  const [user, loading, error] = useAuthState(auth);
  const client = useSelector((state) => state.client.client);
  const { save_client, fetch_client, set_redux } = useStore((state) => state);
  useEffect(() => {
    // console.log(client);
    client ? save_client(client) : fetch_client(client) && set_redux();
    createUserProfileDocument(user, { displayName: user?.displayName });
  }, [client, user]);
  // const query = firestore.collection('users').doc(user?.id);
  // const [currentUser] = useCollectionData(query, { idField: 'id' });
  // console.log('currentUser', currentUser);
  if (loading) {
    return (
      <div className="loading">
        <ClipLoader color={"green"} size={150} />{" "}
      </div>
    );
  }
  // console.log(user);
  if (user) {
    // dispatch(setCurrentUser(user));
    // dispatch(setCurrentClient(client));
    // set_client(client);
    // firestore.collection('clients').onSnapshot((snapshot) => {
    //   dispatch(
    //     setAllClients(
    //       snapshot.docs.map((doc) => ({
    //         id: doc.id,
    //         ...doc.data(),
    //       }))
    //     )
    //   );
    // });
    // var citiesRef = firestore.collection('users');

    // var query = citiesRef?.where('email', '==', user?.email);
    // query
    //   .get()
    //   .then((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {
    //       // doc.data() is never undefined for query doc snapshots
    //       setCurrentUser({
    //         id: doc.id,
    //         ...doc.data(),
    //       });
    //       // console.log(doc.id, ' => ', doc.data());
    //     });
    //   })
    //   .catch((error) => {
    //     console.log('Error getting documents: ', error);
    //   });
    // console.log(currentUser);
    // // firestore.collection('quotes').onSnapshot((snapshot) => {
    // //   setQuotes(
    // //     snapshot.docs.map((doc) => ({
    // //       id: doc.id,
    // //       ...doc.data(),
    // //     }))
    // //   );
    // // });

    return <LoadApp />;
  }
  if (!user) {
    return (
      <Switch>
        <Route
          exact
          path="/sign-up"
          render={() => (user ? <Redirect to="/" /> : <SignUp />)}
        />
        <Route
          exact
          path="/sign-in"
          render={() => (user ? <Redirect to="/" /> : <SignIn />)}
        />
        <Route exact path="*" component={ErrorPage} />
      </Switch>
    );
  }
  return <div className="loading">{error}</div>;
};

export default App;
