import "./invoice.style.scss";

import { useEffect, useState } from "react";

import { BodyComponent } from "../../components/layouts/body-component";
import { Link } from "react-router-dom";
import { firestore } from "../../firebase/firebase.utils";
import {
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import { useStore } from "./../../zustand/store";
import { useSelector } from "react-redux";
import { update } from "lodash";

export const InvoiceList = () => {
  const [pro, setPro] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const UsersRef = firestore.collection("payments");
  // const query = UsersRef.where('PID', "!=", false)
  const query = UsersRef;
  const [invoices] = useCollectionData(query, { idField: "id" });
  useEffect(() => {
    // const q = invoices && invoices?.map((doc) => ({ id: doc.id, ...doc.data() }));
    const results =
      invoices &&
      invoices.filter(
        (quote) =>
          quote?.companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          quote?.companyType.toLowerCase().includes(searchTerm.toLowerCase()) ||
          quote?.payStatus.toLowerCase().includes(searchTerm.toLowerCase()) ||
          quote?.PID.toLowerCase().includes(searchTerm.toLowerCase()) ||
          quote?.payStatus.toLowerCase().includes(searchTerm.toLowerCase())
        // quote.LastName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    setSearchResults(results);

    // console.log(q);
  }, [searchTerm, invoices]);
  // const allClients = useSelector((state) => state.allClients);
  const { setIID, setData, setCurrentClient, allClients } = useStore(
    (state) => state
  );

  return (
    <BodyComponent className="InvoiceList">
      <header>
        <h1 className="title"> Payments </h1>
        <Link
          to={{
            message: "pick a client before making a quote",
            // pathname: _client ? '/quote' : '/clients',
            pathname: "/quote-list",
          }}
          className="button"
        >
          view Quotes
        </Link>
      </header>
      <section>
        <input
          type="text"
          placeholder="Search..."
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
        <div className="i">
          <div className="line paid"> Paid </div>
          <div className="line deposit"> Deposit </div>
        </div>
      </section>
      <main>
        {searchResults?.map((doc) => (
          <div className={`${doc.payStatus} card`} key={doc.id}>
            {/* <i className='bx bx-box out'> </i> */}
            {/* <div className='i out'>
              R {doc.Remaining !== 0 && doc.Remaining}
            </div> */}
            <div className="bar">
              <h1> {doc.PID} </h1>
              {doc.companyType === "private" ? (
                <i className="bx bxs-building-house"></i>
              ) : doc.companyType === "public" ? (
                <i className="bx bx-buildings"> </i>
              ) : (
                <i className="bx bx-user"> </i>
              )}
            </div>
            <section>
              <h1> {doc.companyName} </h1>
              {doc.Invoices && <i className="items">{doc.Inovice?.length} </i>}
              {/* 
              <div className='tag'>
                <span> {doc.payStatus} </span>
              </div> */}
              {doc.payStatus === "fullyPaid" ? (
                <Link
                  className="button"
                  onClick={async () => {
                    const cc = await allClients.filter(
                      (c) => c.id === doc.clientID
                    );
                    await setCurrentClient({ ...cc[0] });
                    // const UsersRef = firestore.doc(`projects/${doc.projectID}`);
                    // console.log(doc)
                    // const query = UsersRef.where("id", "==", doc.projectID);
                    // const query = UsersRef
                    // let snapshot = await UsersRef.get().then(function (
                    //   document
                    // ) {
                    //   console.log(document);
                    // });
                    // const snapshot = await firestore
                    //   .collection("projects")
                    //   .get()
                    //   .docs.map((doc) => doc.data());
                    const docRef = firestore
                      .collection("projects")
                      .doc(doc.projectID);

                    await docRef
                      .get()
                      .then(async (pro) => {
                        if (pro.exists) {
                          // console.log("Document data:", pro.data());
                          const p = pro.data();
                          // console.log("project", project);
                          let cp = p.QID;
                          // console.log(cp);
                          const w = cp.replace("Q", "I");
                          await setIID(w);
                          const quote = {
                            ...p,
                            IID: w,
                            companyType: doc.Type,
                            Type: "Invoice",
                            // Quote: p.Quote,
                            progress: p.progress + 25,
                            Total: p.Total,
                          };
                          // console.log(quote);
                          await setData(quote);
                        } else {
                          // doc.data() will be undefined in this case
                          console.log("No such document!");
                        }
                      })
                      .catch((error) => {
                        console.log("Error getting document:", error);
                      });
                    // console.log(snapshot);
                    // let project = await snapshot.docs.map((doc) => {
                    //   doc.data();
                    // });
                    // // ().then((doc) => {
                    // //   if (doc.exists) {
                    // //     project = doc.data();
                    // //   }
                    // // })
                    // let cp = doc.PID;
                    // const w = cp.replace("P", "I");
                    // await setIID(w);
                    // console.log("project", project);
                    // const quote = [
                    //   {
                    //     ...project,
                    //     IID: w,
                    //     companyType: doc.Type,
                    //     Type: "Invoice",
                    //     Quote: project.Quote,
                    //     progress: project.progress + 25,
                    //     Total: project.Total,
                    //   },
                    // ];
                    // console.log(quote);
                    // project = p.data()
                    // const quote = {
                    //   ...doc,
                    //   Data: doc.Quote,
                    //   Type: 'Invoice',
                    //   Total: doc.Balance,
                    // };
                    // console.log(cc);
                  }}
                  to="/invoices"
                >
                  <span>generate</span>
                </Link>
              ) : (
                <Link
                  className="button"
                  onClick={async () => {
                    const cc = allClients.filter((c) => c.id === doc.clientID);
                    setCurrentClient({ ...cc[0] });
                    console.log(doc);
                  }}
                  to={{
                    pathname: "/payments",
                    state: { action: "update", ...doc },
                  }}
                >
                  <span>complete payment</span>
                </Link>
              )}
              <div className="button">
                <div>
                  <i className="bx bx-box"></i>
                </div>
                <div>
                  <i className="bx bxs-send"></i>
                </div>
              </div>
            </section>
          </div>
        ))}
      </main>
    </BodyComponent>
  );
};
export default InvoiceList;
