import React from 'react';
import ReactDOM from 'react-dom';

import store from './redux/store';

// import NotificationProvider from './Notification/NotificationProvider';

import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
// import './scss/main.scss'
import 'boxicons';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { QueryClient, QueryClientProvider } from 'react-query';
const client = new QueryClient();
ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={client}>
      <Router>
        <App />
      </Router>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);
