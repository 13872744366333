import { clientTypes } from './client.types';
const INITIAL_STATE = {
  client: null,
};

export const clientReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case clientTypes.SET_CURRENT_CLIENT:
      return {
        ...state,
        client: action.payload,
      };

    case clientTypes.CLEAR_CURRENT_CLIENT:
      return {
        ...state,
        client: null,
      };

    default:
      return state;
  }
};
