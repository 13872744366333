import "./pay-form.style.scss";

import { Link, Redirect } from "react-router-dom";
import { useEffect, useState } from "react";

import CurrencyInput from "react-currency-input-field";
// import DatePicker from "react-datepicker";
import { FFV } from "./../../firebase/firebase.utils";
import Modal from "react-awesome-modal";
// import { PayList } from "../pay-list/pay-list.component";
import { auth } from "../../firebase/firebase.utils";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import { useStore } from "../../zustand/store";

// import TextareaAutosize from 'react-textarea-autosize';

const Payments = ({ location, history }) => {
  const prefix = "R ";

  const [user] = useAuthState(auth);
  const [visible, setVisibility] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [Note, setNote] = useState("");
  const [File, setFile] = useState("");
  // const [Status, setStatus] = useState("");
  const [RecNo, setRecNo] = useState("");
  const [pin, setPin] = useState("");
  const [Amount, setAmount] = useState(0);
  // const [Balance, setBalance] = useState("");
  const { setPayCode, _client, payCode } = useStore((state) => state);
  // var win;

  const Quote = location.state;
  console.log(Quote);
  // const [user] = useAuthState(auth);
  useEffect(() => {
    setPayCode(Math.random().toFixed(0) * 2, user.uid.slice(0, 2));
    // setRecNo(payCode);
    // console.log(Quote);
    // if (Quote?.Type === 'credit') {
    //   var pin_ = prompt('please enter your pin to bypass payment..');
    //   console.log(pin_);
    //   pin_ !== user.uid &&
    //     history.push('/quote-list') &&
    //     toast.warning(`You can't give credit... `);
    // }
  }, [setPin, Quote]);

  if (!location.state) {
    return (
      <div className="pay-form error">
        <Redirect push to="/quote-list" />
      </div>
    );
  }
  if (!Quote.outstanding) {
    return (
      <div className="pay-form error">
        <h1>{Quote.projectID}</h1>
        <h3>this project is all paid up</h3>
        <Link to="/pay-list" className="button">
          <button>back to projects</button>
        </Link>
      </div>
    );
  }
  const handlePay = async () => {
    // console.log(Quote);

    // const Remaining = ((Quote.Total - +Amount) / Quote.Total) * 100;
    // if(amount)

    var DownloadURL = "";
    import("../../firebase/firebase.utils").then(
      async ({ createDoc, storage, UpdateDoc }) => {
        if (Quote.Type === "eft") {
          if (await File) {
            const ref = storage.ref();
            const POPref = ref.child(`POP/POP_${Quote.quoteID}.pdf`);
            const task = POPref.put(File);
            const snap = await task.snapshot;
            DownloadURL = await snap?.ref.getDownloadURL();
            // console.log(snap);
            // DownloadURL = task.then((snapshot) => {
            //   // snapshot.ref.getDownloadURL().then(function (downloadURL) {
            //   //   DownloadURL = downloadURL;
            //   //   console.log('File available at', downloadURL);
            //   // });
            //   snapshot.ref.getDownloadURL().then(function (downloadURL) {
            //     return downloadURL;
            //   });
            // });
          } else {
            var pin = prompt(
              "please enter your pin to bypass proof of payment.."
            );
            if (pin !== user.uid) {
              alert("Pin wrong, sorry you can save this payment...");
              return;
            }
          }
        }
        if (Amount === 0) return toast.warning("Please enter an amount...");
        // if (Quote.Type === 'cash' && RecNo === '')
        //   return toast.warning('Please enter a receipt number...');
        if (Quote.action === "update") {
          if (Quote.outstanding <= Amount) {
            await UpdateDoc(
              "payments",
              { uniqueField: "QID", uniqueValue: Quote.id },
              {
                quoteStatus: "done",
                outstanding: 0,
                payStatus: "fullyPaid",
                paid: [Amount, ...Quote.paid],
                // payments: { PID: { ...pay } }
              }
            ).catch((error) => {
              return toast.warning(error.message);
            });
            await UpdateDoc(
              "projects",
              { uniqueField: "QID", uniqueValue: Quote.id },
              {
                quoteStatus: "done",
                outstanding: 0,
                payStatus: "fullyPaid",
                paid: [Amount, ...Quote.paid],
                // payments: { PID: { ...pay } }
              }
            ).catch((error) => {
              return toast.warning(error.message);
            });
            history.push("/invoice-list");
            toast.warning("you just completed payment for " + Quote.PID);
          } else {
            await UpdateDoc(
              "payments",
              { uniqueField: "QID", uniqueValue: Quote.id },
              {
                paid: [Amount, ...Quote.paid],
                outstanding: Quote.outstanding - Amount,
              }
            ).catch((error) => {
              return toast.warning(error.message);
            });
            history.push("/invoice-list");
          }
        } else {
          Quote.Total = Quote.outstanding;
          const half = Quote.Total / 2;
          console.log("half", half);
          let Remaining = Quote.outstanding - parseFloat(Amount);

          console.log("remaining", Remaining);
          let Status;
          if (Remaining > half) {
            return toast.warning(
              `A deposit of R${half} or more, needs to be paid to proceed...`
            );
          } else if (Remaining > 0) {
            Status = "paid";
          } else {
            Status = "fullyPaid";
            Remaining = 0;
          }

          setVisibility(true);
          // if (remaining >= half) setStatus('deposit');
          const PID = RecNo || payCode;
          const pay = {
            payNote: Note,
            // ...Quote,
            clientID: Quote.clientID,
            projectID: Quote.id,
            companyType: Quote.companyType,
            companyName: Quote.companyName,
            clientEmail: Quote.clientEmail,
            PID,
            payStatus: Status,
            payType: Quote.Type,
            paid: [Amount],
            outstanding:
              Status === "fullyPaid" ? 0 : Quote.outstanding - Amount,
            DownloadURL,
            // projectID: Quote.projectID,
          };
          // console.log(pay);
          // (pin || File) && console.log('Pin true: ', pay);
          // Barlow,Open Sans,Lucida Grande,Helvetica Neue,Helvetica,Arial,Sans-serif;
          // try {
          // import {
          //   auth,
          //   createDoc,
          //   storage,
          //   UpdateDoc,
          // } from '../../firebase/firebase.utils';

          await UpdateDoc(
            "projects",
            { uniqueField: "QID", uniqueValue: Quote.id },
            {
              quoteStatus: "approved",
              updatedAt: Date.now(),
              outstanding: Remaining,
              // payments: { PID: { ...pay } }
              payments: FFV.arrayUnion(PID),
            }
          ).catch((error) => {
            return toast.warning(error.message);
          });
          await createDoc(
            "payments",
            {
              uniqueField: "PID",
              uniqueValue: PID,
            },
            { ...pay }
          ).catch((error) => {
            // console.log(error.message);
            return toast.error(error.message);
          });
          await UpdateDoc(
            "clients",
            {
              uniqueField: "Payments",
              uniqueValue: Quote.clientID,
            },
            {
              Payments: FFV.arrayUnion(PID),
              Active: "Yes",
            }
          ).catch((error) => {
            return toast.error(error.message);
          });
        }
        // } catch (error) {
        //   toast.warning(error.message);
        // }
      }
    );
    // await UpdateDoc(
    //   'clients',
    //   { uniqueField: 'quoteID', uniqueValue: Quote.id },
    //   { status: 'approved' }
    // ).catch((error) => {
    //   toast.warning(error.message);
    // });
    // await setData({ ...pay, Type: 'Invoice' });
    // await setVisibility(false);
    // await (win = <Redirect to={'/'} />);
  };
  // const setAmount = (value) => {
  //   setAmount(value);
  // };
  // const upFile = (File, quoteID) => {
  //   // e.preventDefault();
  // };
  return (
    <div className="pay-form">
      <div className="pay-head">
        <h1>Payments</h1>
        <Modal
          visible={visible}
          effect="fadeInUp"
          // onClickAway={() => setVisibility(false)}
        >
          <div className="modal">
            <div className="center">
              <i className="bx bx-check"></i>
              <div className="">
                {/* <Link
                      onClick={() => handleChange()}]
                      to='/invoices'
                      className='p'
                    >
                      save and Generate Invoice
                    </Link> */}
                <Link className="btn" to="/invoice-list">
                  Done
                </Link>
                {/* <button>Add another payment</button> */}
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div className="pay-box">
        {/* <div className='bx list' tabIndex='0'> */}
        {/* <div className='quote'>
                <span>
                  <i className='bx bx-box'></i>
                </span>
                <div className='titles'>
                  <span className='name'>Items</span>
                  <span className='quantity'>Qty</span>
                </div>
                <div className='items'>
                  {Quote.Quote.map((q, index) => (
                    <div className='item' key={index}>
                      <span className='name'>{q.product}</span>
                      <span className='quantity'>x {q.quantity}</span>
                    </div>
                  ))}
                </div> 6XzY18U7DaOg3IFgmC8G5I8JK3n1
              </div> */}
        {/* </div> */}
        <div className="bx form">
          <div className="wrapper">
            <div className="form">
              <div className="quotecode">
                <h1>{Quote.QID}</h1>
                <h4>
                  {Quote.companyName ?? Quote.FirstName + " " + Quote.LastName}
                </h4>
              </div>
              {Quote.Type === "cash" && (
                <div className="mark">
                  {payCode}
                  <i className="bx bx-wallet-alt type"></i>
                </div>
              )}
              {Quote.Type === "eft" && (
                <div className="mark">
                  {payCode}
                  <i className="bx bx-wallet type"></i>
                </div>
              )}
              {Quote.Type === "credit" && (
                <div className="mark">
                  {payCode}
                  <i className="bx bx-credit-card-front type"></i>
                </div>
              )}
              <div className="balance">
                {Amount !== 0 && <div className="remaining"> - {Amount}</div>}

                <CurrencyInput
                  placeholder="Please enter a number"
                  prefix={prefix}
                  className="input"
                  groupSeparator={" "}
                  step={1}
                  value={Quote.outstanding}
                  decimalScale={2}
                  disabled
                />

                <span>Balance</span>
              </div>
              <div className="i">
                <span>Date</span>
                {/* <DatePicker
                  className="input date"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  // className='input'
                /> */}
              </div>
              {/* <div className='i'>
                  <span>Method of payment</span>
                  <Select
                    className='select'
                    placeholder='Select Option'
                    value={methods.find((obj) => obj.value === PayMethod)} // set selected value
                    options={methods} // set list of the methods
                    onChange={(e) => {
                      setPayMethod(e.value);
                    }} // assign onChange function
                  />
                </div> */}

              {Quote.Type === "eft" && (
                <div className="i">
                  <span>Proof of payment</span>
                  <input
                    type="file"
                    className="file"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                  />
                </div>
              )}
              {Quote.Type === "cash" && (
                <div className="i">
                  <span>Receipt No.</span>
                  <input
                    type="text"
                    className="input x"
                    onChange={(e) => {
                      setRecNo(e.target.value);
                    }}
                    value={RecNo}
                    placeholder="Receipt"
                  />
                </div>
              )}
              <div className="i">
                <span>Amount</span>
                <CurrencyInput
                  placeholder="Please enter an amount"
                  prefix={prefix}
                  className="input v"
                  groupSeparator={" "}
                  step={1}
                  // value={Amount}
                  // onValueChange={(value) => setAmount(value)}
                  onValueChange={(value) => {
                    setAmount(value);
                    // set(value);
                  }}
                  decimalScale={2}
                />{" "}
              </div>
              <div className="i">
                <span>Note</span>

                <textarea
                  className="input"
                  placeholder="Just a single line..."
                  name=""
                  id=""
                  cols="20"
                  rows="2"
                  onChange={(e) => setNote(e.target.value)}
                ></textarea>
              </div>
            </div>
            {/* <NavLink
              onMouseEnter={() => handleChange()}
              to={{ pathname: '/invoice', state: [] }}
            > */}
            <button className="p" onClick={() => handlePay()}>
              {/* <button className='p' onClick={() => handleChange()}> */}
              Pay
            </button>
            {/* </NavLink> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
