import { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { auth } from "../../firebase/firebase.utils";
import "./Header.style.scss";
import { ToastContainer } from "react-toastify";
// import ShoppingIcon from './../shopping-icon/shoppingIcon.component';
// import { useSelector, connect, useDispatch } from 'react-redux';
// import { setAllClients } from '../../redux/all-client/all-clients.actions';
import Modal from "react-awesome-modal";
// import { Logo } from '../water-marks/logo';
// import {
//   clearCurrentClient,
//   setCurrentClient,
// } from '../../redux/client/client.actions';
import { useStore } from "./../../zustand/store";

const Header = ({ children }) => {
  const { currentUser } = useStore((state) => state);
  const show = () => {
    const toggle = document.getElementById("header-toggle");
    const nav = document.getElementById("nav-bar");
    const bodypd = document.getElementById("body-pd");
    const headerpd = document.getElementById("header");
    // Validate that all variables exist
    // show navbar
    nav.classList.toggle("show");
    // change icon
    toggle.classList.toggle("bx-x");
    // add padding to body
    bodypd.classList.toggle("body-pd");
    // add padding to header
    headerpd.classList.toggle("body-pd");
  };

  /*===== LINK ACTIVE  =====*/
  const linkColor = document.querySelectorAll(".nav__link");
  function colorLink() {
    if (linkColor) {
      linkColor.forEach((l) => l.classList.remove("active"));
      this.classList.add("active");
    }
  }
  linkColor.forEach((l) => l.addEventListener("click", colorLink));

  // const currentUser = useSelector((state) => state.user.currentUser);
  // console.log(currentUser);
  const [visible, setVisibility] = useState(false);
  // const dispatch = useDispatch();
  return (
    <div className="body" id="body-pd">
      <ToastContainer />

      <Modal
        visible={visible}
        effect="fadeInDown"
        onClickAway={() => setVisibility(false)}
      >
        <div className="clients-modal">
          {/* <i className='bx bx-x' onClick={() => setVisibility(false)}></i> */}
          <h1>Clients</h1>
          <i class="fas fa-house-user"></i>
          <div className="client">
            <span>
              <i class="bx bx-user"></i>
            </span>
            <span>
              <h2>Vusi Mtshali</h2>
              <small>vusimuzivks@gmail.com</small>
            </span>
          </div>
        </div>
      </Modal>
      <header className="header" id="header">
        <div className="header__toggle">
          <i className="bx bx-menu" id="header-toggle" onClick={show}></i>
          <img
            className="h img"
            src={window.location.origin + "/logo.png"}
            alt="logo"
          />
        </div>
        {/*    <div className='search' onClick={() => setVisibility(true)}>
          <i class='bx bx-search'></i>
        </div> */}

        <Link to="/profile" className="user">
          <div className="uid">{currentUser.id}</div>
          <i class="bx bx-user-x"></i>
          {/* {user.email} */}
        </Link>
        {/*  <ShoppingIcon /> */}
      </header>
      <div className="l-navbar" id="nav-bar">
        <nav className="nav">
          <div>
            <Link to="/" className="nav__logo" onClick={() => null}>
              <i class="bx bxs-left-arrow"></i>
            </Link>

            <div className="nav__list">
              <Link to="/" className="nav__link active">
                <i className="bx bx-grid-alt nav__icon"></i>
                <span className="nav__name">Dashboard</span>
              </Link>

              {/* <Link to='invoice-list' className='nav__link'>
                <i type='solid' className='bx bx-book-alt nav__icon'></i>
                <span className='nav__name'>invoices</span>
              </Link> */}
              {/* <Link to='staff' className='nav__link'>
                <i class='bx bx-group nav__icon'></i>
                <span className='nav__name'>Staff</span>
              </Link> */}
              {/* <Link to='quotes' className='nav__link'>
                <i type='solid' className='bx bx-user nav__icon'></i>
                <span className='nav__name'>Quotes</span>
              </Link> */}
              {currentUser?.department === "marketing" && (
                <>
                  <Link to="clients" className="nav__link">
                    <i type="solid" className="bx bx-user nav__icon"></i>
                    <span className="nav__name">Clients</span>
                  </Link>
                  <Link to="/quote-list" className="nav__link">
                    <i className="bx bx-box nav__icon"></i>
                    <span className="nav__name">Quotes</span>
                  </Link>
                  <Link to="/invoice-list" className="nav__link">
                    <i className="bx bx-note nav__icon"></i>
                    <span className="nav__name">Invoices</span>
                  </Link>

                  <Link to="/pay-list" className="nav__link">
                    <i class="bx bx-paint-roll  nav__icon"></i>
                    <span className="nav__name">Projects</span>
                  </Link>
                  <Link to="user-list" className="nav__link">
                    <i class="bx bx-server nav__icon"></i>
                    <span className="nav__name">Staff</span>
                  </Link>
                </>
              )}
              <Link to="/job-list" className="nav__link">
                <i class="bx bx-food-menu nav__icon"></i>
                <span className="nav__name">Jobs</span>
              </Link>
              <Link to="/products" className="nav__link">
                <i class="bx bx-cart nav__icon"></i>
                <span className="nav__name">Products</span>
              </Link>
              <Link to="/staff-list" className="nav__link">
                <i class="bx bx-layer nav__icon"></i>
                <span className="nav__name">summary</span>
              </Link>
              <Link to="/settings" className="nav__link">
                <i class="bx bx-cog nav__icon"></i>
                <span className="nav__name">Settings</span>
              </Link>
            </div>
          </div>
          {currentUser ? (
            <div to="/" onClick={() => auth.signOut()} className="nav__link">
              <i className="bx bx-log-out nav__icon"></i>
              <span className="nav__name">Log Out</span>
            </div>
          ) : (
            <Redirect to="/sign-in" />
          )}
        </nav>
      </div>
      <div className="children">{children}</div>
    </div>
  );
};

// const mapDispatchToProps = (dispatch) => ({
//   setAllClients: (allClients) => dispatch(setAllClients(allClients)),
// });
export default Header;
