import { useState, useEffect } from "react";
import { BodyComponent } from "../../components/layouts/body-component";
import "./staff.style.scss";
import { useStore } from "./../../zustand/store";
function StaffList() {
  const { users } = useStore((state) => state);
  //
  const [busyUsers, setBusyUser] = useState([]);
  const [nonBusyUsers, setNonBusyUser] = useState([]);
  useEffect(() => {
    setBusyUser(users && users.filter((user) => user?.workingOn?.length >= 1));
    setNonBusyUser(users && users.filter((user) => !user?.workingOn?.length));
    // console.log(users)
  }, [users]);
  return (
    <BodyComponent className="staff-list">
      <h1>StaffList</h1>
      {busyUsers ?? <h1 style={{ color: "#8dc63f" }}> Working </h1>}
      <main>
        {busyUsers &&
          busyUsers.map((user, index) => (
            <div
              className="card"
              key={user.index + Math.random()}
              style={{ borderColor: "#8dc63f" }}
            >
              <section>
                <h1>{user.displayName ?? "unknown"}</h1>
                <article>
                  <span>{user.workedOn[0]}</span>
                  <p>Current Project</p>
                </article>
              </section>
              <section>
                <div className="i">
                  <span>{user.workingOn?.length || 0}</span>
                  <span>working on</span>
                </div>
                <div className="i">
                  <span>{user.workedOn?.length || 0}</span>
                  <span>worked on</span>
                </div>
              </section>
            </div>
          ))}
      </main>
      <h1 style={{ color: "#0f3d60" }}>Not working</h1>
      <main>
        {nonBusyUsers &&
          nonBusyUsers.map((user, index) => (
            <div className="card not-working" key={index}>
              <section>
                <h1>{user.displayName || <span>unknown</span>}</h1>
              </section>
              <section>
                <div className="i">
                  <span>{user.workedOn?.length || 0}</span>
                  <span>worked On</span>
                </div>
              </section>
            </div>
          ))}
      </main>
    </BodyComponent>
  );
}

export default StaffList;
