import { allClientsTypes } from './all-clients.types';
const INITIAL_STATE = {
  allClients: null,
};

export const allClientsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case allClientsTypes.SET_ALL_CLIENTS:
      return {
        ...state,
        allClients: action.payload,
      };

    /*     case allClientsTypes.CLEAR_ALL_CLIENTS:
      return {
        ...state,
        allClients: null,
        client: null,
      }; */

    default:
      return state;
  }
};
