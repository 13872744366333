import React, { Suspense } from "react";

import { useEffect, useState } from "react";
import { auth, firestore } from "../../firebase/firebase.utils";
// import { useAuthState } from "react-firebase-hooks/auth";
import { useStore } from "../../zustand/store";
import { Switch, Route, Redirect } from "react-router-dom";
//
import { Settings } from "../settings/settings.component";
import Payments from "../pay-form/pay-form.component";
import Staff from "../staff/staff.component";
import JobCard from "../job-card/job-card.component";
// import { Products } from '../product-view/product.component';
import AllClients from "../../components/clients-grid/clients-list.components";
import QuoteList from "../quo/quoteList.component";
import Jobs from "../job/job.component";
import ClientPay from "../client-pay/client-pay.component";
import Profile from "../profile/profile.component";
import InvoiceList from "../invoiceList/invoice-list.component";
import PayList from "../pay-list/pay-list.component";
import UserList from "../user-list/user-list.component";
import Quote from "../quote/quote.components";
import Header from "../../components/header/Header.components";
// import PayForm from "../pay-form/pay-form.component";
// import Gen from "../quote-generation/gen.compoent";
// import Dashboard from "../../pages/dashboard/dashboard.components";
// import AddClient from "../add-client/add-client.component";
// import underConstruction from "../under-construction/underConstruction.component";
// import ClientFiles from "../../components/client-files/client-files.components";
// import SignIn from "../../components/sign-in/SignIn.component";
// import SignUp from "../../components/sign-up/SignUp.component";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { JobList } from "../job-list/job-list.component";
// import { ClipLoader } from "react-spinners/ClipLoader";
import StaffList from "../staff-list/staff-list.component";
// import { WelcomePage } from '../welcome/welcome-page.compoent';

export const LoadApp = () => {
  // firestore.collection('clients').onSnapshot((snapshot) => {
  //   dispatch(
  //     setAllClients(
  //       snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }))
  //     )
  //   );
  // });

  const { currentUser: user } = auth;
  //
  const {
    currentUser,
    setCurrentUser,
    setAllClients,
    setUsers,
    setProjects,
    setProducts,
  } = useStore((state) => state);
  //
  // console.log(user)
  const userRef = firestore.doc(`users/${user.uid}`);
  const dquery = userRef;
  const [current] = useDocumentData(dquery, { idField: "id" });

  const projectRef = firestore.collection("Projects");
  const query = projectRef;
  const [projects] = useCollectionData(query, { idField: "id" });

  const productRef = firestore.collection("products");
  const Pquery = productRef;
  const [products] = useCollectionData(Pquery, { idField: "id" });

  const usersRef = firestore.collection("users");
  const Uquery = usersRef;
  const [users] = useCollectionData(Uquery, { idField: "id" });
  //
  useEffect(() => {
    setCurrentUser(current);
    setProjects(projects);
    setUsers(users);
    setProducts(products);
    // var citiesRef = firestore.doc(`users/${user.uid}`);
    // citiesRef.onSnapshot((doc) =>
    //   setCurrentUser({
    //     id: doc.id,
    //     ...doc.data(),
    //   })
    // );

    firestore.collection("clients").onSnapshot((snapshot) => {
      setAllClients(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
      );
    });
    // unsubscribe && unsubscribe()
  }, [
    setAllClients,
    setCurrentUser,
    user,
    current,
    setProjects,
    projects,
    setUsers,
    users,
  ]);

  const WelcomePage = React.lazy(() =>
    import("../welcome/welcome-page.compoent")
  );
  const Products = React.lazy(() =>
    import("../product-view/product.component")
  );
  const LoadingCom = React.lazy(() =>
    import("../../pages/loading-comp/loading-comp.component")
  );
  const Gen = React.lazy(() => import("../quote-generation/gen.compoent"));
  const Dashboard = React.lazy(() =>
    import("../../pages/dashboard/dashboard.components")
  );
  const AddClient = React.lazy(() =>
    import("../add-client/add-client.component")
  );
  const underConstruction = React.lazy(() =>
    import("../under-construction/underConstruction.component")
  );
  const ClientFiles = React.lazy(() =>
    import("../../components/client-files/client-files.components")
  );
  const SignIn = React.lazy(() =>
    import("../../components/sign-in/SignIn.component")
  );
  const SignUp = React.lazy(() =>
    import("../../components/sign-up/SignUp.component")
  );

  if (!currentUser)
    return (
      <div className="loading">
        <i class="bx bx-loader"></i>
      </div>
      // <LoadingCom>
      //   <span>loading...</span>
      // </LoadingCom>
    );
  if (currentUser?.active === false) {
    return (
      <Suspense
        fallback={
          <div className="loading">
            <i class="bx bx-loader"></i>
          </div>
        }
      >
        <Route exact path="/">
          <WelcomePage />
        </Route>
        <Route exact path="/products">
          <Products />
        </Route>
        <Route
          exact
          path="*"
          render={() => (user ? <Redirect to="/" /> : <SignUp />)}
        />
      </Suspense>
    );
  }

  return (
    <Header>
      <Suspense
        fallback={
          <div className="loading">
            <i class="bx bx-loader"></i>
          </div>
        }
      >
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/invoices" component={Gen} />

          {/* <Route exact path='/inVoVo' component={InVOVO} /> */}
          {currentUser?.department === "autobody" && (
            <Route exact path="/client-files" component={ClientFiles} />
          )}
          <Route exact path="/quote" component={Quote} />
          {currentUser?.department === "marketing" && (
            <Route exact path="/quote-list" component={QuoteList} />
          )}
          {currentUser?.department === "marketing" && (
            <Route exact path="/invoice-list" component={InvoiceList} />
          )}
          {currentUser?.department === "marketing" && (
            <Route exact path="/pay-list" component={PayList} />
          )}
          <Route exact path="/clients" component={AllClients} />
          <Route exact path="/add-client" component={AddClient} />
          <Route exact path="/client-pay" component={ClientPay} />
          <Route exact path="/payments" component={Payments} />
          <Route exact path="/user-list" component={UserList} />
          <Route exact path="/jobs" component={Jobs} />

          {/* <Route exact path='/edit' component={Payments} /> */}
          <Route exact path="/loading" component={underConstruction} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/staff" component={Staff} />
          <Route exact path="/products">
            <Products />
          </Route>
          <Route exact path="/job-list">
            <JobList />
          </Route>
          <Route exact path="/staff-list">
            <StaffList />
          </Route>
          <Route exact path="/profile" component={Profile} />
          <Route
            exact
            path="/sign-up"
            render={() => (currentUser ? <Redirect to="/" /> : <SignUp />)}
          />
          <Route
            exact
            path="/sign-in"
            render={() => (currentUser ? <Redirect to="/" /> : <SignIn />)}
          />
          <Route
            exact
            path="*"
            render={() => (currentUser ? <Redirect to="/" /> : <SignUp />)}
          />
          {/* <Route exact component={ErrorPage} /> */}
        </Switch>
      </Suspense>
    </Header>
  );
};
