import "./quote.style.scss";

import { useEffect, useState } from "react";
import { addNewRow, handleTable, handleTotal, overallSum } from "./script";

import Modal from "react-awesome-modal";
import { NavLink } from "react-router-dom";
// import { auth } from '../../firebase/firebase.utils';
import { toast } from "react-toastify";
// import { useAuthState } from 'react-firebase-hooks/auth';
import { useStore } from "../../zustand/store";
// import { useCollectionData } from 'react-firebase-hooks/firestore';
// import CurrencyInput from 'react-currency-input-field';
export const Quote = () => {
  // const client = useSelector((state) => state.client.client);
  const [visible, setVisibility] = useState(false);
  const state = useStore();
  const {
    setQuoteCode,
    quoteCode,
    today,
    setData,
    Products,
    currentClient,
    setProjectCode,
    currentUser,
    projectCode,
  } = useStore((state) => state);

  const client = currentClient;
  useEffect(() => {
    // setProjectCode(currentUser.id.slice(0, 2));
    setQuoteCode(client.Quotes?.length || 0 + 1, currentUser.id.slice(0, 2));
    const quote = JSON.parse(localStorage.getItem("quote"));
    quote
      ? quote.Quote.map((q) => addNewRow("", Products, q), overallSum())
      : addNewRow("", Products);
    overallSum();
  }, [
    overallSum,
    setQuoteCode,
    setProjectCode,
    overallSum,
    addNewRow,
    currentUser,
    client,
    Products,
  ]);

  const handleSubmit = async (e) => {
    let data = [];
    var rows = document.querySelectorAll("tr.single-row");
    await rows.forEach((currentRow) => {
      var product = currentRow.querySelector("#product")?.value;
      var quantity = currentRow.querySelector("#Quantity")?.value;
      var price = currentRow.querySelector("#price")?.value;
      var amount = currentRow.querySelector("#amount")?.value;
      if (product === "") {
        currentRow.querySelector("#product")?.classList.add("undone");
        // console.log('finish your quote dummy');
        toast("Finish your quote before proceeding...");
        return;
      } else if (quantity === "") {
        currentRow.querySelector("#Quantity")?.classList.add("undone");
        // console.log('finish your quote dummy');
        toast("Finish your quote before proceeding...");
        return;
      } else if (price === "") {
        currentRow.querySelector("#price")?.classList.add("undone");

        toast("Finish your quote before proceeding...");
        return;
        // } else if (vat.value === '') {
        //   vat?.classList.add('undone');
        //   toast('Finish your quote before proceeding...');
        //   return;
      } else {
        data.push({ product, quantity, price, amount });
      }
    });
    if (data[0] === null) return console.log(data);
    const Total = document.getElementById("total").value;
    data = data?.filter((da) => da?.product?.length > 0);
    const clientType =
      client.Type !== "walk-in"
        ? { companyName: client.Company }
        : {
            FirstName: client.FirstName,
            LastName: client.LastName,
            companyName: `${client.FirstName} ${client.LastName}`,
          };
    // Total = Total * 0.85
    const quote = {
      ...clientType,
      companyType: client.Type,
      Type: "Quote",
      clientID: client.id,
      projectID: projectCode,
      Quote: data,
      Total,
      outstanding: Total,
      progress: 25,
    };
    // setQuote(quote);
    // setQuote(data);
    await setData(quote);
    await console.log("This is my quote", quote);
    await console.log("this that zustand :", state.Data);
    const notDone = document.querySelector(".undone");
    if (!notDone) await setVisibility(true);
    if (!data.length <= 0) await setVisibility(true);
  };
  return client ? (
    <div className="client-form">
      <div className="MakeQuote">
        <div className="container">
          <div className="quote-container">
            <div className="main-header">
              <Modal
                visible={visible}
                effect="fadeInUp"
                onClickAway={() => setVisibility(false)}
              >
                <div
                  className="modal"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <h1>Data saved</h1>
                  <h3>Ready for preview...</h3>
                  <i class="far fa-question-circle"></i>
                  <div className="decide">
                    <NavLink to="/invoices">
                      <button>Continue</button>
                    </NavLink>

                    <button onClick={() => setVisibility(false)}>
                      Return to Quote
                    </button>
                  </div>
                </div>
              </Modal>
            </div>

            <div className="quote-header">
              <div className="title-date">
                <div className="date">
                  <div className="due">
                    <span>Date</span>
                    <input
                      value={today}
                      disabled
                      style={{ textAlign: "right" }}
                    />
                  </div>
                  {client.RegNo && (
                    <div className="due">
                      <span>VAT No.</span>
                      <input
                        type="text"
                        value={client.VATNo || ""}
                        disabled
                        id="VaT"
                        placeholder="VAT Number"
                      />
                    </div>
                  )}
                  {client.RegNo && (
                    <div className="due">
                      <span>Reg No.</span>
                      <input
                        type="text"
                        value={client.RegNo || ""}
                        disabled
                        id="Rag"
                        placeholder="Reg Number"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="quote-number">
                <h2 className="title-q">Quotation</h2>
                <h3>{quoteCode}</h3>
                <br />
                {client.FirstName
                  ? client.FirstName + " " + client.LastName
                  : client.Company}
                <br />
                {client.Email}
              </div>
            </div>
            <div className="quote-body">
              <table>
                <thead>
                  <tr>
                    <th style={{ paddingLeft: "16px" }}>PRODUCT</th>
                    <th>Qty</th>
                    <th>PRICE</th>
                    <th>AMOUNT</th>
                    <th>ACTION</th>
                  </tr>
                </thead>

                <tbody
                  id="table-body"
                  onClick={(e) => {
                    handleTable(e);
                  }}
                  onKeyUp={(e) => handleTotal(e)}
                >
                  <tr style={{ paddingLeft: "20px", textAlign: "center" }}>
                    <td className="" style={{ textAlign: "center" }}>
                      <div className="float">
                        <div
                          className="float"
                          id="add-row"
                          onClick={() => addNewRow("", Products)}
                        >
                          <i className="bx bx-plus"></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div id="sum">
                <h1>Total</h1>
                <input
                  type="text"
                  placeholder="0.00"
                  name="total"
                  className="total"
                  id="total"
                  disabled
                />
              </div>
            </div>
            <div className="undoneMessage">
              The quote form is uncompleted, fill in the boxes in red to
              proceed...
            </div>
            <div className="quote-footer">
              <div className="q-btn" onClick={() => handleSubmit()}>
                Save
              </div>
              {/* <NavLink
                to={{
                  pathname: 'invoices',
                  state: { Quote },
                }}
                className='q-btn'
                onMouseEnter={() => handleSubmit()}
              >
                Save
              </NavLink> */}
              {/* <div className='q-btn' onClick={() => handleSubmit()}>
                Email
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="quote_no_client">
      <div className="warning">
        <i class="bx bx-error"></i>
        <h2>in order to create a quote, you need to pick a client</h2>
        <NavLink to="/clients">
          <button>pick a client</button>
        </NavLink>
      </div>
    </div>
  );
};
export default Quote;
