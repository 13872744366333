import "./job.style.scss";

const Jobs = () => {
  return (
    <div className="jobs">
      <h1>Jobs</h1>
      <section>
        <div className="list">
          <div className="search">
            <input type="text" />
          </div>
          <div className="divider">
            <div className="payments">
              <span>
                <i className="bx bx-credit-card"></i>
                Payment
              </span>
            </div>
            <div className="invoices">
              <span>
                <i className="bx bx-receipt"> </i>
                Invoice
              </span>
            </div>
          </div>
          <div className="box">
            <div className="tab">
              <h2>QUO-465768</h2>
              <h4>fully paid</h4>
              <i className="bx bx-receipt"></i>
            </div>
            <div className="tab">
              <h2>PAY-465768</h2>
              <section>
                <div>Serame group</div>
                <div>
                  <i className="bx bx-dollar-circle"></i> : R 4 567
                </div>
                <div>
                  <i className="bx bx-file"></i> : POP_866976.pdf
                </div>
              </section>
              <i className="bx bx-credit-card"></i>
            </div>
          </div>
        </div>
        <main>
          <div className="order">
            <div className="items">web hvkjvk</div>
            <i className="bx bx-package icon"></i>
          </div>
          <div className="people">
            <i className="bx bx-user-circle"></i>
            <div className="add">
              <i className="bx bx-plus"></i>
            </div>
          </div>
          <textarea
            cols="30"
            rows="5"
            placeholder="anything more..."
          ></textarea>
          <button>send job card</button>
        </main>
      </section>
    </div>
  );
};
export default Jobs;
