import { useMediaQuery } from "react-responsive";
import "./client-pay.style.scss";
const ClientPay = () => {
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <div className="client-pay">
      <h1>client payments</h1>
      <main>
        {(isTabletOrMobile && <div className="small"></div>) ||
          (isBigScreen && <div className="big"></div>)}

        <section>
          <div className="file">
            <span>
              <i className="fas fa-file-invoice-dollar"></i>
            </span>
            <div className="">Paid: R56879</div>
          </div>
          <div className="search">
            <input type="text" placeholder="search payments..." />
          </div>
          <div className="list"></div>
        </section>
      </main>
    </div>
  );
};
export default ClientPay;
