import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { toast } from "react-toastify";
const config = {
  apiKey: "AIzaSyBgLMg5oFFRt8aObJO7P8IgvjotiyM9ZiY",
  authDomain: "serame-db.firebaseapp.com",
  projectId: "serame-db",
  storageBucket: "serame-db.appspot.com",
  messagingSenderId: "701430977613",
  appId: "1:701430977613:web:4a183bd1aabd5ca97f7804",
};
firebase.initializeApp(config);
export const FFV = firebase.firestore.FieldValue;
export const docRef = (ref) => firebase.firestore.collection(ref);
export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;

  const userRef = firestore.doc(`users/${userAuth.uid}`);

  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();
    try {
      await userRef.set({
        displayName,
        email,
        department: "",
        active: false,
        createdAt,
        ...additionalData,
      });
    } catch (error) {
      toast.error("Error creating user", error.message);
    }
  }

  return userRef;
};

export const auth = firebase.auth();
export const authTwo = new firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const batch = firebase.batch;
// invoice
export const createInvoice = async (invoiceData) => {
  if (!invoiceData) return;
  const invoiceRef = firestore.collection(`invoices`);

  return invoiceRef;
};
// client

// export const getClients = () => {
//   firestore.collection('clients').onSnapshot((querySnapshot) => {
//     querySnapshot.docs.map((doc) => {
//     name: doc.data().FirstName,
//     })
//   });
// };

export const createDoc = async (doc, uF, docData) => {
  if (!docData && !doc) return;
  const { serverTimestamp } = firebase.firestore.FieldValue;
  const docRef = firestore.collection(doc);

  auth.onAuthStateChanged((user) => {
    if (user) {
      docRef
        .where(uF.uniqueField, "==", uF.uniqueValue)
        .get()
        .then(async (a) => {
          if (a.empty) {
            await docRef.add({
              ...docData,
              createdAt: serverTimestamp(),
              createdBy: user.email,
            });
          } else {
            toast.warn("Record already exists...", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          // console.log(user.displayName);
        })
        .catch((error) => {
          toast(error.message);
        });
    } else {
      toast.error("You not authenticated...", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  });
};

export const UpdateDoc = async (doc, uF, docData) => {
  if (!docData && !doc) return;
  const { serverTimestamp } = firebase.firestore.FieldValue;
  const docRef = firestore.doc(`${doc}/${uF.uniqueValue}`);

  auth.onAuthStateChanged((user) => {
    if (user) {
      docRef
        .get()
        .then(async (a) => {
          if (!a.empty) {
            docRef.update({
              ...docData,
              createdAt: serverTimestamp(),
            });
          } else {
            console.log("its no document to update here");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("user not authenticated");
    }
  });
};

// const putData = (container, data) => {
//   const div = document.getElementById(container)
//     ? (document.getElementById(container).innerHTML = data)
//     : null;
//   return div;
// };
export const getDocData = (collection, arr) => {
  firestore.collection(collection).onSnapshot((snapshot) => {
    return snapshot.docs.map((doc) => doc.data());
    // const c = { name: displayName };
    // arr.forEach((c, index, arr) => data.map((doc) => console.log(doc.index)));
    // data.map((doc) => console.log(Object.values(doc)));
    // data.map((doc) => console.log(Object.keys(doc)));
    // data.map((doc) => {
    //   const d = 'displayName';
    // });
    // return data;
  });
};

//
//
//
//
//
//
//
//
//
export const createClient = async (clientData) => {
  if (!clientData) return;
  const clientRef = firestore.collection(`clients`);
  const findEmailMatch = (email) => {
    clientRef
      .where("Email", "==", email)
      .get()
      .then(async (a) => {
        if (a.empty) {
          await clientRef.add({ ...clientData });
        } else {
          console.log("its here");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  findEmailMatch(clientData.Email);
  return clientRef;
};

export const getClients = () => {
  let unsubscribe;
  let a, b;
  unsubscribe = auth.onAuthStateChanged((user) => {
    if (user) {
      unsubscribe = firestore
        .collection("clients")
        .onSnapshot((querySnapshot) => {
          a = querySnapshot.docs.map(
            (doc) => `<h1>${doc.data().FirstName}</h1>`
          );
          // console.log('Current clients in our database: ', a);
          // document.write(a);
          document.getElementById("myClients").innerHTML = a.join("");
          document.getElementById("myClients-total").innerHTML = a.length;
        });
      firestore.collection("clients").onSnapshot((querySnapshot) => {
        b = querySnapshot.docs.map((doc) => doc.data().FirstName);
        document.getElementById("myClients-latest").innerHTML = b[0];
      });
    } else {
      unsubscribe && unsubscribe();
    }
  });
  return a;
};

/* const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' }); */

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => auth.signInWithPopup(provider);
export default firebase;
