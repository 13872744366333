import './settings.style.scss';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase/firebase.utils';
import { toast } from 'react-toastify';
export const Settings = () => {
  const [user] = useAuthState(auth);
  return (
    <div className='settings'>
      <header>
        <h1 className='title'>Settings</h1>
      </header>
      <main>
        <section className='bankingDetails'>
          {/* <div className='mask'>
            <i className='bx bx-message-square-edit'></i>
            <h2>Edit Banking details</h2>
          </div> */}
          <header>
            <i className='bx bxs-bank'></i>
            <h2>Banking Details</h2>
          </header>
          <div className='i'>
            <div className='label'>Bank</div>
            <input type='text' />
          </div>
          <div className='i'>
            <div className='label'>Name</div>
            <input type='text' />
          </div>
          <div className='i'>
            <div className='label'>Account Number</div>
            <input type='text' />
          </div>
          <div className='i'>
            <div className='label'>Account Type</div>
            <input type='text' />
          </div>
          <div className='i'>
            <div className='label'>Branch Code</div>
            <input type='text' />
          </div>
          <button className='btn'>Update Details</button>
        </section>

        <section>
          <header>
            <i className='bx bx-cctv'></i>
            <h2>Misc Settings</h2>
          </header>
          <div
            className='i'
            onmousedown='return false;'
            onselectstart='return false;'
          >
            <div className='label'>your key</div>
            <input
              type='text'
              value={user?.uid}
              id='input'
              autocomplete='off'
              disabled
            />
            <i
              className='bx bxs-copy'
              onClick={() => {
                navigator.clipboard.writeText(user?.uid); // to copy to clipboard
                toast.success('password copied...', {
                  position: 'bottom-center',
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }}
            ></i>
          </div>
          <div
            className='i'
            onmousedown='return false;'
            onselectstart='return false;'
          >
            <div className='label'>Vat</div>
            <input type='text' value={'15%'} autocomplete='off' disabled />
            <i className='bx bxs-pencil'></i>
          </div>
        </section>
      </main>
    </div>
  );
};
