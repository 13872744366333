import { useState } from "react";
import { auth, signInWithGoogle } from "../../firebase/firebase.utils";
import { Link } from "react-router-dom";
import { FormInput } from "../sign-form-input/FormInput.components";
import { CustomButton } from "../custom-button/CustomButton.components";

import "./SignIn.style.scss";
import { toast, ToastContainer } from "react-toastify";

const SignIn = () => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     email: '',
  //     password: '',
  //   };
  // }
  const handleGoogleSign = async (e) => {
    try {
      await signInWithGoogle();
      // await auth.createUserProfileDocument(auth.user, { auth.displayName });
    } catch (error) {
      // toast(error.message);
      toast(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    // const { email, password } = this.state;
    if (Email && Password)
      try {
        auth.signInWithEmailAndPassword(Email, Password).catch((error) => {
          toast(error.message);
        });
        // this.setState({ email: '', password: '' });
      } catch (error) {
        toast("Something, went wrong...");
      }
    // toast('Make sure all fields are filled... ');
  };
  const handleForgot = (params) => {
    Email
      ? auth
          .sendPasswordResetEmail(Email)
          .then(() => {
            toast.success("Email sent, check your email...");
          })
          .catch((error) => {
            toast(error.code);
            toast(error.message);
            // ..
          })
      : toast(
          'Enter your email in the email field, and press "forgot password?" again...'
        );
  };
  return (
    <div className="form-container">
      <div className="form">
        <ToastContainer />
        <form onSubmit={handleSubmit} className="form-sign">
          <h2 className="title">Sign in</h2>

          <FormInput
            FA_icon="fas fa-envelope"
            name="email"
            type="email"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />

          <FormInput
            FA_icon="fas fa-lock"
            name="password"
            type="password"
            value={Password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
            autoComplete="disabled"
          />
          {/*<input type='submit' value='Sign In' className='btn solid' />*/}

          <CustomButton type="submit">Sign in</CustomButton>
          {/* <CustomButton >
              Sign in w/ Google
            </CustomButton> */}
          <i
            class="bx bxl-google google"
            onClick={(e) => handleGoogleSign(e)}
          ></i>
        </form>
        <div className="giveSpace">
          <p>
            <button onClick={() => handleForgot()}>
              {" "}
              Forgot your password?
            </button>
          </p>
          <span>
            Don't have an account, sign up here [
            <Link to="/sign-up"> {` Sign Up `} </Link>]
          </span>
        </div>
      </div>
    </div>
  );
};
export default SignIn;
