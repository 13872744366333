import { Component } from "react";
import { Link } from "react-router-dom";
import { auth, createUserProfileDocument } from "../../firebase/firebase.utils";

import { FormInput } from "../sign-form-input/FormInput.components";
import { CustomButton } from "../custom-button/CustomButton.components";

import "./SignUp.style.css";
import { toast, ToastContainer } from "react-toastify";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: "",
      email: "",
      password: "",
      confirmPassword: "",
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };
  handleSubmit = async (e) => {
    e.preventDefault();

    const { displayName, email, password, confirmPassword } = this.state;
    if (password !== confirmPassword) {
      return toast("password don't match");
    }
    try {
      const { user } = await auth
        .createUserWithEmailAndPassword(email, password)
        .catch((error) => {
          toast(error.message);
        });
      await createUserProfileDocument(user, {
        displayName,
        active: false,
      });
      await user
        .updateProfile({
          displayName: displayName,
          active: false,
          h: "i was set in sign up",
        })
        .then(function () {
          // return toast(' Welcome ', user.h ? 'active' : 'notActive');
          return toast("Welcome ", user.displayName);
          // return toast('Sign-in, successful... Welcome ', user.h);
        })
        .catch(function (error) {
          return toast("Sorry... " + error.message);
        });
      this.setState({
        displayName: "",
        email: "",
        password: "",
        confirmPassword: "",
      });
    } catch (error) {
      toast(error);
    }
  };

  render() {
    const { displayName, email, password, confirmPassword } = this.state;
    return (
      <div className="form-container">
        <div className="form">
          <form onSubmit={this.handleSubmit} className="form-sign">
            <ToastContainer />
            <h2 className="title">Sign up</h2>
            <FormInput
              FA_icon="fas fa-user"
              name="displayName"
              value={displayName}
              type="text"
              onChange={this.handleChange}
              placeholder="Display Name"
            />
            <FormInput
              FA_icon="fas fa-envelope"
              name="email"
              value={email}
              type="email"
              onChange={this.handleChange}
              placeholder="Email"
            />
            <FormInput
              FA_icon="fas fa-unlock"
              name="password"
              value={password}
              type="password"
              onChange={this.handleChange}
              placeholder="Password"
            />
            <FormInput
              FA_icon="fas fa-lock"
              name="confirmPassword"
              value={confirmPassword}
              type="password"
              onChange={this.handleChange}
              placeholder="Confirm Password"
            />
            <CustomButton type="submit">Sign Up</CustomButton>
            <span>
              Already have an account, sign in here{" "}
              <Link to="/sign-in"> Sign in</Link>
            </span>
          </form>
        </div>
      </div>
    );
  }
}

export default SignUp;
