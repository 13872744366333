import "./error.style.css";
import { Redirect } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase/firebase.utils";
const ErrorPage = () => {
  const [user] = useAuthState(auth);
  if (!user) {
    return <Redirect to="/sign-in" />;
  }
  return (
    <div className="error-page">
      <div className="message">
        <h1>404</h1>
        <h6>Error loading this page...</h6>
        <p>redirect to homepage</p>
      </div>
    </div>
  );
};
export default ErrorPage;
